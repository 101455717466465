import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    //授权链接
    async getAuthorizationUrl({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + `/op/meituan/authorization/${params.storeId}`, params);
      return data;
    },
    //所有兑换策略
    async getAllStrategy({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + `/op/meituan/consume/strategy`, params);
      return data;
    },
    //城市的兑换策略
    async getCityStrategy({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + `/op/meituan/consume/strategy/${params.cityCode}`, params);
      return data;
    },
    //城市消费策略配置
    async setCityStrategy({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/op/meituan/consume/strategy/${params.cityCode}/setting`, params);
      return data;
    },
    //获取门店消费配置
    async getStoreConfig({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/op/meituan/consume/store/config", params);
      return data;
    },
    async setDefaultStore({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/meituan/consume/strategy/defaultStore/set", params);
      return data;
    },
    async cancelDefaultStore({ rootState, commit }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/meituan/consume/strategy/defaultStore/unSet", params);
      return data;
    }

  }
};

export default store;
